<template>
	<NavBar></NavBar>
	<div class="home">
		<section class="one-banner">
			<div class="grid-container">
				<div class="odm-title">
					<h1>Sustainable & Zero Carbon ODM Service</h1>
					<div class="bottle-container">
						<img src="@/assets/images/banner01.svg" alt="bottle">
					</div>
				</div>
			</div>
			<div class="banner-img"></div>
		</section>
		<section class="two-menu">
			<div class="grid-container">
				<div class="menu-block">
					<h2 class="menu-title">Why Choose <span>O’right</span> ?</h2>
					<div class="menu-list" v-for="list in menu" :key="list.id" @click="goTo(list.anchor)">
						<div>{{ list.text }}</div>
						<div class="flex menu-list-action">
							<div class="num">{{ list.id }}</div>
							<div class="icon"><img src="@/assets/images/icon/i_down.svg" alt="down_icon"></div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="SectionOne">
			<div class="grid-container">
				<div class="intro-block-one flex">
					<div data-aos="fade-up" data-aos-duration="1000" class="img-container">
						<img src="@/assets/images/a01.jpg" alt="O'right">
					</div>
					<div class="text">
						<div class="text-group flex" data-aos="fade-right" data-aos-duration="500">
							<h4>Scope 3 Comprehensive Carbon Neutrality for Three Consecutive Years</h4>
							<div class="certi-img">
								<img src="@/assets/images/a02.jpg" alt="certificate">
							</div>
						</div>
						<h6 data-aos="fade-right" data-aos-duration="500" data-aos-delay="200">O’right has been verified by SGS since 2020 and has achieved organizational carbon neutrality for three consecutive years; including all products from raw material acquisition, manufacturing and production, transportation and sales, consumer use to waste recycling, covering the scope of direct emissions and indirect emissions Triple fully carbon neutral.</h6>
					</div>
				</div>
			</div>
		</section>
		<section class="section-one-branch">
			<div class="grid-container">
				<div class="intro-block-two flex">
					<div class="img-container">
						<img src="@/assets/images/a03.svg" alt="zero carbon">
					</div>
					<div class="text">
						<h4 data-aos="fade-right">The global trend towards zero-carbon products enhances corporate competitiveness</h4>
						<ul class="text-lists">
							<li class="item" data-aos="fade-up" data-aos-delay="100">Consumer behavior changes, gradually moving towards net-zero sustainability.</li>
							<li class="item" data-aos="fade-up" data-aos-delay="200">Establish a zero-carbon business model and improve the efficiency of energy resource use.</li>
							<li class="item" data-aos="fade-up" data-aos-delay="300">Energy conservation, carbon reduction, and net-zero sustainability are basic requirements of consumers.</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		<section id="SectionTwo">
			<div class="bg-cover cover-one">
				<div class="grid-container">
					<div class="content">
						<h2>O’right Green Building</h2>
						<div class="para">
							<p>Taiwan’s first diamond-level low-carbon green factory</p>
							<p>Sustainable symbiosis with the natural ecology</p>
						</div>
						<div class="btn" data-aos="fade-right">
							<a href="https://vod-icbu.alicdn.com/4f4e1c368ac918af/76f77ccd139b5b26/20221031_5c8cce55fbbeb1d3_383458940219_mp4_264_hd_unlimit_taobao.mp4?t=212b8ecd16693592051728149eeb49&b=icbu_video&p=cloudvideo_http_icbu_vod_crm" target="_new">
								<span>See our factory</span>
								<span class="icon">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 25 25">
										<defs>
											<clipPath id="clip-path">
											<rect id="Rectangle_3834" data-name="Rectangle 3834" width="25" height="25" transform="translate(11.86 11)" fill="#fff"/>
											</clipPath>
										</defs>
										<g id="i_link" transform="translate(-11.86 -11)" clip-path="url(#clip-path)">
											<g id="Group_3096" data-name="Group 3096" transform="translate(12.882 15.763)">
											<rect id="Rectangle_3831" data-name="Rectangle 3831" width="3" height="12" rx="1.5" transform="translate(19.403 12.237) rotate(-180)" fill="#fff"/>
											<rect id="Rectangle_3832" data-name="Rectangle 3832" width="3" height="12" rx="1.5" transform="translate(7.403 3.237) rotate(-90)" fill="#fff"/>
											<rect id="Rectangle_3833" data-name="Rectangle 3833" width="3" height="21" rx="1.5" transform="translate(4.554 17.207) rotate(-135)" fill="#fff"/>
											</g>
										</g>
									</svg>
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="main-content">
				<div class="grid-container flex">
					<div class="building-image" data-aos="fade-up" data-aos-delay="500">
						<div class="img-container">
							<img src="@/assets/images/b02.jpg" alt="green building">
						</div>
					</div>
					<div class="text-title">
						<div class="title-inner">
							<h3>International<br>Standards & Quality</h3>
							<div class="deco-border"><img src="@/assets/images/border.svg" alt="border"></div>
						</div>
					</div>
					<div class="text-content flex">
						<div class="item" v-for="item in certilist" :key="item.id" data-aos="zoom-out">
							<h4>{{ item.type }}</h4>
							<h6>{{ item.name }}</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="bg-cover cover-two">
				<div class="grid-container">
					<div class="content flex">
						<div class="factory-image">
							<img src="@/assets/images/b03.png" alt="green factory">
						</div>
						<div class="title-inner">
							<h3>Manufacturing<br>Facilities</h3>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="SectionThree">
			<div class="grid-container">
				<div class="taf-ceri flex">
					<div class="text-content">
						<div class="taf-image">
							<img src="@/assets/images/c01.png" alt="TAF">
							<span>( TAF is equivalent to ilac-MRA)</span>
						</div>
						<div class="taf-list">
							<h4 data-aos="fade-right">International Accredited TAF Laboratory Quality Assurance</h4>
							<ul class="text-lists text-lists-no-bg">
								<li class="item" data-aos="fade-up" data-aos-delay="100">Human live skin transdermal water loss test</li>
								<li class="item" data-aos="fade-up" data-aos-delay="200">Human live skin epidermal water content test</li>
								<li class="item" data-aos="fade-up" data-aos-delay="300">Human live skin melanin test</li>
								<li class="item" data-aos="fade-up" data-aos-delay="400">total bacterial count</li>
								<li class="item" data-aos="fade-up" data-aos-delay="500">Preservative efficacy test</li>
							</ul>
						</div>
					</div>
					<div class="beaker-image">
						<img src="@/assets/images/c02.jpg" alt="beaker">
					</div>
				</div>
			</div>
		</section>
		<section class="section-three-branch">
			<div class="grid-container">
				<div class="lab-intro">
					<h4>Three Independent Labs</h4>
					<div class="structure-image" data-aos="fade-up" data-aos-delay="100">
						<img class="imgInPc" src="@/assets/images/en/c03.svg" alt="structure of lab">
						<img class="imgInMobile" src="@/assets/images/en/c03_m.svg" alt="structure of lab">
					</div>
				</div>
			</div>
			<div class="lab-image-pc pc-left">
				<img class="imgInPc" src="@/assets/images/c04.png" alt="the lab" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="100">
				<img class="imgInMobile" src="@/assets/images/c04_m.png" alt="the lab">
			</div>
			<div class="lab-image-pc pc-right" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="100">
				<img  class="imgInPc" src="@/assets/images/c05.png" alt="the lab">
			</div>
		</section>
		<section class="three-branch-2">
			<div class="grid-container">
				<div class="certi-description flex">
					<div class="text-content">
						<h4>Regulatory Certification</h4>
						<div class="lists">
							<p><span>EU</span>：Self-edited PIF, 138 items registered with 6 government certified safety assessor qualified personnel.</p>
							<p><span>USDA Biobased certification：79 products and 4 raw materials</span> have passed the certification and are constantly updated.</p>
							<p><span>Vegan certification</span>, the United States/Germany/Swiss International Three Invention Awards… and other awards continue to apply.</p>
						</div>
					</div>
					<div class="certi-img">
						<img src="@/assets/images/c07.jpg" alt="Regulatory Certification">
					</div>
				</div>
			</div>
		</section>
		<section class="three-branch-3">
			<div class="grid-container">
				<h4 data-aos="fade-right">O’right Innovative Ingredients<br>Assigned with INCI Names</h4>
				<div class="ings-content flex">
					<div class="item" data-aos="fade-up" data-aos-delay="300">
						<div class="rect-img">
							<img src="@/assets/images/c13.png" alt="INIC">
						</div>
						<span></span>
					</div>
					<div class="item" data-aos="fade-up" data-aos-delay="300">
						<div class="round-img">
							<img src="@/assets/images/c08.png" alt="coffee husk">
						</div>
						<span>Water (and) Coffea Arabica (Coffee) Husk Extract</span>
					</div>
					<div class="item" data-aos="fade-up" data-aos-delay="300">
						<div class="round-img">
							<img src="@/assets/images/c09.png" alt="lycium">
						</div>
						<span>Lycium Chinense Root Extract</span>
					</div>
					<div class="item" data-aos="fade-up" data-aos-delay="500">
						<div class="round-img">
							<img src="@/assets/images/c10.png" alt="coffee seed oil">
						</div>
						<span>Coffea Arabica (Coffee) Seed Oil</span>
					</div>
					<div class="item" data-aos="fade-up" data-aos-delay="500">
						<div class="round-img">
							<img src="@/assets/images/c11.png" alt="coffee seed">
						</div>
						<span>Coffea Arabica (Coffee) Seed Extract</span>
					</div>
					<div class="item" data-aos="fade-up" data-aos-delay="500">
						<div class="round-img">
							<img src="@/assets/images/c12.png" alt="aspergillus">
						</div>
						<span>Aspergillus / Sorghum Bicolor Ferment Lees Extract</span>
					</div>
				</div>
			</div>
		</section>
		<section id="SectionFour">
			<div class="grid-container">
				<div class="text-and-image flex">
					<div class="usda-content" data-aos="fade-right">
						<h4>USDA Biobased Certification</h4>
						<ul class="text-lists text-lists-no-bg">
							<li class="item">Scientific data management</li>
							<li class="item">All ingredients of the product are sent to beta laboratory for testing</li>
						</ul>
					</div>
					<div class="img-container">
						<img src="@/assets/images/d01.jpg" alt="USDA">
					</div>
				</div>
			</div>
		</section>
		<section id="SectionFive">
			<div class="grid-container">
				<div class="text-and-image flex">
					<div class="img-container">
						<img src="@/assets/images/e02.jpg" alt="vegan">
					</div>
					<div class="vegan-content" data-aos="fade-left">
						<h4>100% Vegan ingredients<br>used only</h4>
						<p>The 17 Sustainable Development Goals (SDGs) proposed by the United Nations have attracted the attention of governments and business units around the world. Contributing to environmental sustainability has become a prominent topic and global trend.</p>
					</div>
				</div>
			</div>
		</section>
		<section id="SectionSix">
			<div class="grid-container">
				<h4><span>Green</span> environmentally<br>friendly packaging materials</h4>
				<div class="swiper-content">
					<swiper
                    class="story-swiper"
                    :modules="modules"
					:pagination="{ clickable: true, }"
					:effect="'fade'"
                    :autoplay="{
                        delay: 2000,
                        disableOnInteraction: false,
                    }"
                    >
                        <swiper-slide>
                            <div class="img-container">
								<img src="@/assets/images/en/f01.png" alt="PCR">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="img-container">
								<img src="@/assets/images/en/f02.png" alt="PCR bottle">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="img-container">
								<img src="@/assets/images/en/f03.png" alt="ZERO Impact">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="img-container">
								<img src="@/assets/images/en/f04.png" alt="USDA">
                            </div>
                        </swiper-slide>
                    </swiper>
				</div>
			</div>
		</section>
		<section id="SectionSev">
			<div class="grid-container">
				<div class="curve-content flex">
					<div class="text-wrap" data-aos="fade-left" data-aos-duration="600">
						<div class="text-content">
							<h4>The 2nd Grow Curve of Green Beauty Industry</h4>
							<div class="curve-image">
								<img src="@/assets/images/en/f06.svg" alt="curve image">
							</div>
							<p>Having your beauty products with zero carbon emission is the 2nd Grow Curve for your business.</p>
						</div>
					</div>
					<div class="cover-image" data-aos="fade-up" data-aos-duration="1000">
						<img src="@/assets/images/f05.jpg" alt="curve cover">
					</div>
				</div>
				<div class="customization-progress">
					<h4>Vertically Integrated Customization Service</h4>
					<div class="progress-image">
						<img class="imgInPc" src="@/assets/images/en/f07.svg" alt="progress-image">
						<img class="imgInMobile" src="@/assets/images/en/f07_m.svg" alt="progress-image">
					</div>
				</div>
				<div class="product-intro">
					<h4>Customizable Zero Carbon<br>Beauty Products</h4>
				</div>
			</div>
			<div class="footer-image flex">
				<div class="text-content"  data-aos="zoom-in" data-aos-duration="1000">
					<div class="img-container">
						<img src="@/assets/images/footer/f08.png" alt="Hair Care">
						<div class="type">Hair care</div>
					</div>
					<p>Shampoo, conditioner, hair oil, hair cream, dry shampoo, scalp spray, hair lotion, hair conditioner, styling oil, styling spray, scalp protection gel,…etc.</p>
				</div>
				<div class="text-content"  data-aos="zoom-in" data-aos-duration="1000">
					<div class="img-container">
						<img src="@/assets/images/footer/f09.png" alt="Hair Care">
						<div class="type">Skin care</div>
					</div>
					<p>Cleanser, lotion, essence, cream, mask…etc.</p>
				</div>
				<div class="text-content"  data-aos="zoom-in" data-aos-duration="1000">
					<div class="img-container">
						<img src="@/assets/images/footer/f10.png" alt="Hair Care">
						<div class="type">Personal care</div>
					</div>
					<p>Toothpaste, dry cleaning spray, dry cleaning gel, anti-mosquito lotion, eyebrow serum, mascara…etc.</p>
				</div>
				<div class="text-content"  data-aos="zoom-in" data-aos-duration="1000">
					<div class="img-container">
						<img src="@/assets/images/footer/f11.png" alt="Hair Care">
						<div class="type">Body care</div>
					</div>
					<p>Shower gel, hand cream, hand curtain silk, hand soap, massage oil, perfume…etc.</p>
				</div>
			</div>
		</section>
		<footer>
			<div class="grid-container">
				<div class="title">Contact Us</div>
				<a class="mail" href="mailto:Oright_OEMODM@oright.inc">
					<span class="icon"><img src="@/assets/images/icon/i_mail.svg" alt="mail icon"></span>
					<span>Oright_OEMODM@oright.inc</span>
				</a>
				<div class="btn">
					<a href="https://www.surveycake.com/s/WXQnx" target="_new">
						<span>Sustainable & Zero Carbon ODM Service Application</span>
						<span class="icon">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 25 25">
								<defs>
									<clipPath id="clip-path">
									<rect id="Rectangle_3834" data-name="Rectangle 3834" width="25" height="25" transform="translate(11.86 11)" fill="#fff"/>
									</clipPath>
								</defs>
								<g id="i_link" transform="translate(-11.86 -11)" clip-path="url(#clip-path)">
									<g id="Group_3096" data-name="Group 3096" transform="translate(12.882 15.763)">
									<rect id="Rectangle_3831" data-name="Rectangle 3831" width="3" height="12" rx="1.5" transform="translate(19.403 12.237) rotate(-180)" fill="#fff"/>
									<rect id="Rectangle_3832" data-name="Rectangle 3832" width="3" height="12" rx="1.5" transform="translate(7.403 3.237) rotate(-90)" fill="#fff"/>
									<rect id="Rectangle_3833" data-name="Rectangle 3833" width="3" height="21" rx="1.5" transform="translate(4.554 17.207) rotate(-135)" fill="#fff"/>
									</g>
								</g>
							</svg>
						</span>
					</a>
				</div>
			</div>
			<h6 class="copyright">Copyright © {{ new Date().getFullYear() }} O’right Inc. All rights reserved.</h6>
		</footer>
		<transition name="fade" mode="out-in">
			<div v-if="showSideIcon" class="scrollTop">
				<div class="toTopBtn" @click="scrollToTop">
					<img class="imgInPc" src="@/assets/images/icon/i_top.svg" alt="top">
					<img class="imgInMobile" src="@/assets/images/icon/i_top_m.svg" alt="top">
				</div>
				<div class="linkBtn">
					<a class="toBtmBtn" href="https://www.surveycake.com/s/WXQnx" target="_new">Contact Us</a>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { reactive, ref, onMounted, onUnmounted } from 'vue';
import { Pagination, FreeMode, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/vue";
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import '../assets/scss/swiper.scss';

export default {
	name: 'HomeView',
	components: {
		NavBar,
        Swiper,
        SwiperSlide,
    },
	setup() {
		const menu = reactive([
			{
				id: '01',
				text: 'The world’s only zero-carbon beauty green factory',
				anchor: 'SectionOne',
			},
			{
				id: '02',
				text: 'Asia’s first green cosmetics GMP factory',
				anchor: 'SectionTwo',
			},
			{
				id: '03',
				text: 'Professional certified laboratories & exclusively developed raw materials',
				anchor: 'SectionThree',
			},
			{
				id: '04',
				text: 'USDA Biobased natural and sustainable certification',
				anchor: 'SectionFour',
			},
			{
				id: '05',
				text: '100% vegan ingredients used only',
				anchor: 'SectionFive',
			},
			{
				id: '06',
				text: 'Green environmentally friendly packaging materials',
				anchor: 'SectionSix',
			},
			{
				id: '07',
				text: 'Zero-carbon sustainable ODM services',
				anchor: 'SectionSev',
			},
		]);
		const certilist = reactive([
			{
				id: '01',
				type: 'ISO/TS 14067',
				name: 'Carbon Footprint of Product',
			},
			{
				id: '02',
				type: 'ISO 9001',
				name: 'Quality Management Systems',
			},
			{
				id: '03',
				type: 'ISO 14064-1',
				name: 'Organization Quantification and Reporting of Greenhouse Gases',
			},
			{
				id: '04',
				type: 'ISO 45001',
				name: 'Occupational Health and Safety Management Systems',
			},
			{
				id: '05',
				type: 'ISO 14046',
				name: 'Environmental management - Water footprint - Principles',
			},
			{
				id: '06',
				type: 'ISO 50001',
				name: 'Energy Management System',
			},
			{
				id: '07',
				type: 'ISO 11930',
				name: 'Microbiology - Evaluation of the antimicrobial protection of a cosmetic product',
			},
			{
				id: '08',
				type: 'ISO 22716',
				name: 'Guidelines on Good Manufacturing Practices',
			},
			{
				id: '09',
				type: 'IPAS 2060',
				name: 'Standard for Carbon Neutrality',
			},
			{
				id: '10',
				type: 'ISO 17025',
				name: 'Accreditation Criteria for the Competence of Testing and Calibration Laboratories',
			},
			{
				id: '11',
				type: 'TIPS',
				name: 'Taiwan Intellectual Property Management System',
			},
			{
				id: '12',
				type: 'ISO 14001',
				name: 'Environmental Management Systems',
			},
			{
				id: '13',
				type: 'GMP',
				name: 'Good Manufacturing Practice',
			},
		])
		const showSideIcon = ref(false);
		const goTo = (anchor) => {
			const el = document.querySelector(`#${anchor}`);
			if (el) {
				el.scrollIntoView({ behavior: 'smooth' });
			}
		}
		const scrollDetect = () => {
			let start = window.innerHeight;
			let scroll = window.pageYOffset;
			if (scroll >= start/4) {
				showSideIcon.value = true;
			} else {
				showSideIcon.value = false;
			}
		}
		const scrollToTop = () => {
			window.scrollTo({top: 0, behavior: 'smooth' });
		}
		onMounted(() => {
			window.addEventListener("scroll", scrollDetect);
		})
		onUnmounted(() => {
            window.removeEventListener("scroll", scrollDetect);
        })
		return {
			menu,
			certilist,
			goTo,
			modules: [Pagination, FreeMode, Autoplay, EffectFade],
			showSideIcon,
			scrollToTop,
		}
	}
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/page.scss";
</style>
