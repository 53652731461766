<template>
    <nav>
		<div class="grid-container">
			<div class="logo-container">
				<img src="@/assets/images/common/logo.svg" alt="O'right ODM">
			</div>
			<div class="right">
				<div class="language">
					<router-link to="/" class="list">English</router-link>
					<router-link to="/ch" class="list">中文</router-link>
				</div>
				<div class="contactBlock">
					<a class="contactBtn" href="https://www.surveycake.com/s/WXQnx" target="_new">Contact Us</a>
				</div>
			</div>
		</div>
	</nav>
</template>

<style lang="scss" scoped>
nav {
	padding-top: 60px;
	@media screen and (max-width: 992px) {
		padding-top: 30px;
	}
	.grid-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		.logo-container {
			img {
				width: 100%;
			}
			@media screen and (max-width: 768px) {
				max-width: 115px;
				width: 100%;
			}
		}
		.language {
			position: relative;
			display: inline-block;
			&::before {
				content: url('@/assets/images/icon/i_lan.svg');
				display: block;
				width: 26px;
				height: 26px;
				position: absolute;
				left: -31px;
				top: 50%;
				transform: translateY(-50%);
			}
			.list {
				color: $black;
				font-size: 22px;
				text-decoration: none;
				padding: .5rem;
				transition: .15s ease-in;
				&:hover {
					color: $green;
				}
				@media screen and (max-width: 768px) {
					font-size: 1rem;
				}
			}
			.router-link-active {
				color: $green;
			}
		}
		.contactBlock {
			display: inline-block;
			@media screen and (max-width: 768px) {
				position: absolute;
				bottom: -48px;
				right: 20px;
			}
			.contactBtn {
				display: inline-block;
				margin-left: 15px;
				text-decoration: none;
				background-color: $white;
				color: $black;
				padding: 15px 35px;
				border-radius: 30px;
				font-size: 22px;
				transition: .15s ease-in;
				@media screen and (max-width: 768px) {
					margin-left: 0;
					font-size: 1rem;
					padding: 10px 25px;
				}
				&:hover {
					background-color: $green;
					color: $white;
				}
			}
		}
	}
}
</style>