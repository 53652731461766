import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createMetaManager, defaultConfig } from 'vue-meta'
const metaManager = createMetaManager(false, {
    ...defaultConfig,
    meta: { tag: 'meta', nameless: true },
})

createApp(App).use(router).use(metaManager).mount('#app')
