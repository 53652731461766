<template>
	<metainfo>
		<template v-slot:title="{ content }">{{ content }}</template>
    </metainfo>
	<router-view/>
</template>

<script>
import { onMounted } from 'vue'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useMeta } from 'vue-meta'

export default {
	setup() {
		onMounted(() => {
			AOS.init({ startEvent: 'load', once: false });
		});
		useMeta({
			title: 'Oright | ODM',
			htmlAttrs: { lang: 'en' },
			meta: [{
				name: 'description',
				content: 'O’right has been verified by SGS since 2020 and has achieved organizational carbon neutrality for three consecutive years; including all products from raw material acquisition, manufacturing and production, transportation and sales, consumer use to waste recycling, covering the scope of direct emissions and indirect emissions Triple fully carbon neutral.'
			}],
		})
	}
}
</script>

<style lang="scss">
@import "../src/assets/scss/main.scss";
</style>
